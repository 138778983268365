.standings-row-container-full-light {
  display: inline-flex;
  background-color: #E2E2E2;
  font-family:'RobotoBold';
}

.standings-row-container-full-dark {
  display: inline-flex;
  background-color: #232323;
}

.standings-row-text-light {
  font-size: 0.75rem;
  margin-left: 0.25rem;
}

.standings-row-data-container-dark {
  display: inline-flex;
  align-items: center;
}

.standings-row-text-dark {
  font-size: 16px;
}

.standings-row-data-container-dark {

}

.standings-row-data-container-light {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin: 0 1rem;
  flex: 1 1 0%;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .standings-row-text-light {
    font-size: 2vw;
  }
}