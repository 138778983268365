.standings-page-select-container {
  display: inline-flex;
  justify-content: right;
  color: white;
  gap: 3%;
}

.standings-page-select-button-container {
  cursor: pointer;
}

.standings-page-select-button-text-selected {
  font-family: 'RobotoBold';
  cursor: pointer;
}

.standings-page-select-button-text {
  cursor: pointer;
}