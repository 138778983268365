.driver-prediction-card-container {
  width: 100%;
  height: 100%;
  background-color: #E2E2E2;
  display: inline-flex;
  position: relative;
  white-space: nowrap;
  align-items: center;
}

.driver-prediction-card-country-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin-right: -2%;
  margin-left: -1.75%;
}

.driver-prediction-card-country-img {
  max-width: 38%;
}

.driver-prediction-card-name-container {
  justify-content: start;
}

.driver-prediction-card-name-text {
  font-family: 'RobotoBold';
  justify-content: start;
  font-size: 0.85rem;
}

.driver-prediction-card-team-container {
  position: absolute;
  left: 85%;
}

.driver-prediction-card-team-text {
  font-size: 0.7rem
}