.frontpage-standings-content-container {
  margin: 0 auto;
  margin-top: 4%;
  border-top: solid 2px #212121;
  max-width: 48rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.frontpage-standings-text {
  font-family: 'RobotoBold';
  font-size: 1rem;
  color: white;
}

.frontpage-standings-container {
  /* width: 100%;
  height: 90%; */
  display: block;
  /* flex-direction: row; */
}

.frontpage-standings-driver-standings-container {
  margin-top: 2rem;
}

@media (min-width: 750px) {
  .frontpage-standings-content-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .frontpage-standings-container {
    display: flex;
    justify-content: space-between;
  }
  
  .frontpage-standings-predictions-standings-container {
    width: 65%;
    display: flex;
    flex-direction: column;
  }

  .frontpage-standings-driver-standings-container {
    margin-top: 0;
  }
}