.standings-row-country-container {
  /* 
  display: inline-flex;
  align-items: center;
  position: absolute;
  margin-left: -2%;

  Don't need these. Content is already centered since 
  parent display is flex and has align-items: center
  */
}

.standings-row-country-img {
  width: 1.5rem;

  /* 
  width: 8%;
  
  Use a fixed size or image will be super small
  on small screens
  */
}
