.content-line {
    background-color: #212121;
    margin-top: 10vh;
    height: 2px;
    width: 100%;
    margin-right: 10%;
}

@media screen and (max-width: 600px) {
  .content-line{
    margin-top: 5vh;
  }
}