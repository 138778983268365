.standings-row-number-box {
  display: inline-flex;
  width: 10%;
  background-color: #232323;
  align-items: center;
  justify-content: center;
}

.standings-row-number-box-text {
  font-family: 'RobotoBold';
  color: white;
}
