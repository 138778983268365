body > div > a {
  text-decoration: none;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal; /* normal | 300 | 400 | 600 | bold | etc */
  src: url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoItalic";
  font-style: normal;
  font-weight: normal; /* normal | 300 | 400 | 600 | bold | etc */
  src: url("./fonts/Roboto/Roboto-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoBold";
  font-style: normal;
  font-weight: normal; /* normal | 300 | 400 | 600 | bold | etc */
  src: url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoExtraBold";
  font-style: normal;
  font-weight: normal; /* normal | 300 | 400 | 600 | bold | etc */
  src: url("./fonts/Roboto/Roboto-Black.ttf") format("truetype");
}

.App {
  height: 100vh;
  width: 100vw;
  position: relative;
  margin: 0;
  background-color: #141414;
  font-family: Roboto;
  overflow: overlay;
}

.error-screen {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 20%;
  display: grid;
  justify-content: center;
  align-items: center;
}
.error-screen-yoshi {
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}