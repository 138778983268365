.logo-header-container {
  color: white;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-left: 14%;
  margin-top: 5vh;
}

.logo-header-title-container {
  word-wrap: normal;
  width: 60%;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-header-title-text {
  font-family: 'RobotoExtraBold';
  font-size: 1.5vw;
}

.logo-header-subtitle-container {
  margin-top: 5%;
  margin-bottom: -4%;
  margin-right: 22.7vw;
}

.logo-header-subtitle-text {
  font-family: 'Roboto';
  letter-spacing: 0.20vw;
  font-size: 0.6vw;
  font-weight: 600;
}

.logo-header-f1-img-container {
  max-width: 10%;
  min-height: 15%;
  margin-left: 14vw;
  margin-top: -6.4%;
}

.logo-header-logo-img-container {
  position: absolute;
  max-width: 8%;
  left: 31.5%;
  top: 5.5%;
  z-index: 5;
}

@media screen and (max-width: 600px) {
  .logo-header-container {
    color: white;
    width: 75%;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-left: 17%;
    margin-top: 3.5vh;
  }

  .logo-header-subtitle-container {
    margin-top: 3vh;
    margin-bottom: -4%;
    margin-right: 21.5vw;
  }

  .logo-header-f1-img-container {
    max-width: 10%;
    min-height: 15%;
    margin-left: 32%;
    margin-top: -10%;
  }
}

@media screen and (min-width: 1600px) {
}


@media only screen and (min-width: 1900px) {
}