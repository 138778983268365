.prediction-form-user-selected-container {
  width: 70%;
}
  
.prediction-form-user-selected-droppable {
  height: 200px;
}

.prediction-form-available-drivers-container {
  width: 4%;
}

.prediction-form-user-info-container {
  width: 20%;
  height: 40%;
}

.prediction-form-country-select {
  color: white;
  background-color: #363636;
  font-family: Roboto;
  font-size: 0.8rem !important;
  border-radius: 0px;
  height: 53.3667px !important;
}

.prediction-form-container {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 70%;
  position: absolute;
  left: 21.5%;
  top: 35%;
  gap: 2%;
}

.prediction-form {
  height: 100%;
  display: block;
  flex-direction: column;
  justify-content: space-evenly;
}

.prediction-form-enter-here-container {
  display: inline-flex;
  justify-content: space-between;
  width: 71%;
}

.prediction-form-drag-and-drop-text {
  font-family: RobotoBold;
  color: #E2E2E2;
  font-size: 1.1rem;
}

.prediction-form-drivers-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 40rem;
  width: 75%;
  gap: 2%;
}

.prediction-form-user-selected-pos-container {
  background: #363636;
  width:15%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prediction-form-user-selected-row {
  display: flex;
  flex-direction: row;
  gap: 30%;
}

.prediction-form-user-selected-column {
  display: flex;
  flex-direction: column;
  width: 80%
}

.prediction-form-user-selected-slot {
  display: flex;
  width: 44%;
  height: 3.25em;
  align-items: center;
}

.prediction-form-user-selected-pos-text {
  font-family: 'RobotoBold';
  color: white;
  width: 7%;
  background: #363636;
  display: flex;
  justify-content: center;
}

.prediction-form-enter-predictions-container {
  width: 90%;
  height: 100%;
}

.prediction-form-enter-predictions-text {
  color: white;
  font-size: 1.3rem;
  font-family: 'RobotoBold';
}

.prediction-form-submit-button-container {
  font-family: RobotoBold;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E2E2E2;
  margin-left: auto; 
  margin-right: 10px;
  padding: 0 10px 0 10px;
  height: 40px;
  cursor: pointer;
}

.prediction-form-submit-button-text {

}

.prediction-form-submit-error-container {
  color: white;
}

.prediction-form-submit-error-text {
  color: red;
  font-size: 0.7rem;
}

.prediction-form-submitted-text {
  color: white;
}

.prediction-form-user-input {
  font-size: 0.7rem;
  color: white;
  background-color: #363636;
  border: 0px;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
  padding-bottom: 0 !important;
  margin-bottom: 0.8rem !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  padding: 5px 13.5px 5px 2px !important;
  border-radius: 0 !important;
  background: #363636 !important;
  color: white !important;
  height: 3.3rem !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7::after {
  border-top: 5px solid #141414 !important;
}

#standard-basic-label {
  margin-left: 10px;
  top: -3px;
}

#standard-basic {
  padding: 5px 10px 5px;
}

.testing {
  margin-bottom: 0.8em;
}

.prediction-dropdown {
  height: 3.25rem;
  margin-bottom: 0.8em;
}

.ReactFlagsSelect-module_selectBtn__19wW7::after {
  border-top: 5px solid #141414;
}

.prediction-form-buttons-container {
  display: inline-flex;
  margin-top: 3%;
  justify-content: space-evenly;
}
